const parser = require('./base');
require('./provider/canalplus');
require('./provider/coub');
require('./provider/dailymotion');
require('./provider/twitch');
require('./provider/vimeo');
require('./provider/wistia');
require('./provider/youku');
require('./provider/youtube');
require('./provider/soundcloud');
module.exports = parser;